define([
        'modules/upx/collections/collection',
        '../models/Product',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ProductsModule',
            object: 'Product',
            collection_method: 'listProducts',
            model: Model
        });
    }
);