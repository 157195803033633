define([
        'modules/upx/models/model',
        './structs/Product'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ProductsModule',
            idAttribute: 'id',
            object: 'Product',
            matchOnSku: function (parameters, ajaxOptions) {
                return this.callObjectMethod('matchProductsOnSku',parameters, ajaxOptions);
            },
            setActive: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setProductActive',parameters, ajaxOptions);
            },
            setNotActive: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setProductNotActive',parameters, ajaxOptions);
            },
            setActiveStatus: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setProductsActiveStatus',parameters, ajaxOptions);
            },
            massUpdate: function (parameters, ajaxOptions) {
                return this.callObjectMethod('massUpdateProducts',parameters, ajaxOptions);
            },
            deleteProducts: function (parameters, ajaxOptions) {
                return this.callObjectMethod('deleteProducts',parameters, ajaxOptions);
            },
            deleteByIds: function (parameters, ajaxOptions) {
                return this.callObjectMethod('deleteProductByIds',parameters, ajaxOptions);
            },
            setPrices: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setProductPrices',parameters, ajaxOptions);
            }
        });
    }
);