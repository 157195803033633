define([
        'modules/upx/models/model',
        './structs/Provider'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',
            idAttribute: 'id',
            object: 'Provider',
            getCallbackUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getProviderCallbackUrl',parameters, ajaxOptions);
            }
        });
    }
);