define([
        'modules/upx/models/model',
        './structs/ProviderMethodType'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',

            object: 'ProviderMethodType'
        });
    }
);