define([
        'modules/upx/models/model',
        './structs/OrderProvider'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'OnlineFoodOrderModule',

            object: 'OrderProvider',
            syncOrderProviders: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncOrderProviders',parameters, ajaxOptions);
            }
        });
    }
);