define([
        'modules/upx/models/model',
        './structs/ProviderMethod'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',
            idAttribute: 'id',
            object: 'ProviderMethod',
            getRefundableIds: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getRefundableProviderMethodIds',parameters, ajaxOptions);
            },
            syncProviderMethods: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncProviderMethods',parameters, ajaxOptions);
            },
            setProviderMethods: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setProviderMethods',parameters, ajaxOptions);
            }
        });
    }
);