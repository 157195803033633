define([
        'modules/upx/models/model',
        './structs/GiftCard'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',
            idAttribute: 'id',
            object: 'GiftCard',
            setGiftCard: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setGiftCard',parameters, ajaxOptions);
            },
            find: function (parameters, ajaxOptions) {
                return this.callObjectMethod('findGiftCard',parameters, ajaxOptions);
            }
        });
    }
);