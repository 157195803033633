define([
        'modules/upx/models/model',
        './structs/FeaturedAttribute'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ProductsModule',
            idAttribute: 'alias',
            object: 'FeaturedAttribute',
            setFeaturedAttribute: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setFeaturedAttribute',parameters, ajaxOptions);
            }
        });
    }
);