define([
        'modules/upx/models/model',
        './structs/GiftCardPayment'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',

            object: 'GiftCardPayment',
            process: function (parameters, ajaxOptions) {
                return this.callObjectMethod('processGiftCardPayment',parameters, ajaxOptions);
            },
            ensure: function (parameters, ajaxOptions) {
                return this.callObjectMethod('ensureGiftCardPayment',parameters, ajaxOptions);
            }
        });
    }
);